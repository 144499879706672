<template>
  <svg class="logo" width="128" height="63" viewBox="0 0 128 63" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M63.2131 11.7526C77.231 11.4832 88.2667 15.2548 96.8267 22.6791C103.045 28.0727 108.246 35.6454 112.268 45.6467C109.203 44.8917 105.964 44.5215 102.573 44.5215C95.399 44.5215 88.9611 46.181 83.5973 49.7923C77.5879 46.1253 71.1232 44.1979 64.2757 44.1979C57.2723 44.1979 50.5495 46.2127 44.1689 49.9949C38.0103 46.1206 31.0708 44.1979 23.4993 44.1979C20.7653 44.1979 18.1493 44.4516 15.6814 44.982C19.2979 35.7838 24.0824 28.693 29.8914 23.4963C38.1921 16.0706 49.1296 12.0232 63.2131 11.7526ZM9.82851 61.4166C10.0384 61.2253 10.235 61.0184 10.4162 60.797C12.9782 57.8239 16.9889 55.9371 23.4993 55.9371C29.6392 55.9371 34.9062 57.6433 39.512 61.015L39.6938 61.1503C42.1964 63.0133 45.6034 63.0901 48.1874 61.3418C53.6911 57.6181 59.0238 55.9371 64.2757 55.9371C69.4346 55.9371 74.4405 57.5573 79.4026 61.0801L79.5061 61.1541C82.2606 63.1246 86.0058 62.9538 88.5695 60.7408C91.8469 57.9117 96.3323 56.2607 102.573 56.2607C109.139 56.2607 114.229 58.0926 118.249 61.4864C118.437 61.6511 118.634 61.8036 118.84 61.9429C119.378 62.3076 119.959 62.572 120.56 62.7383C121.467 62.9906 122.446 63.0279 123.424 62.8063C123.942 62.6895 124.446 62.5023 124.921 62.2458C126.451 61.4203 127.52 59.9599 127.873 58.2959C128.018 57.6127 128.042 56.8951 127.928 56.173C127.894 55.9515 127.847 55.7318 127.787 55.5151C123.13 37.4749 115.517 23.3503 104.518 13.8108C93.4022 4.16947 79.3898 -0.299596 62.9876 0.0155562C46.651 0.329447 32.8403 5.10715 22.0645 14.7471C11.3826 24.303 4.22946 38.1473 0.178326 55.6457C0.118718 55.8829 0.0742537 56.1228 0.044812 56.364C-0.165626 58.0565 0.37625 59.7025 1.43574 60.9265C1.98837 61.5649 2.68183 62.0886 3.4828 62.4442C3.99072 62.6707 4.52237 62.8205 5.06113 62.895C6.83849 63.1446 8.57209 62.5615 9.82851 61.4166Z" fill="white"/>
  </svg>
</template>

<script>
export default {
  props: {},
  data() {
    return {};
  },
  computed: {},
  methods: {},
  components: {}
};
</script>

<style lang="scss" scoped>
.logo {
  width: 100%;
  max-width: 160px;
}
</style>