<template>
  <div @click="toggle" role="button" class="cursor-pointer btn-wallpaper">
    <svg width="43" height="38" viewBox="0 0 43 38" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g filter="url(#filter0_dd_0_371)" class="btn-wallpaper-icon-back">
      <path d="M7.95069 4.06459C8.27968 2.37206 9.91845 1.2667 11.611 1.5957L36.1277 6.36127C37.8202 6.69026 38.9256 8.32903 38.5966 10.0216L35.0224 28.4091C34.6934 30.1016 33.0547 31.207 31.3621 30.878L6.84541 26.1124C5.15288 25.7834 4.04752 24.1447 4.37651 22.4521L7.95069 4.06459Z" fill="white" fill-opacity="0.4"/>
      </g>
      <g class="btn-wallpaper-icon-front">
        <g filter="url(#filter1_d_0_371)">
        <path d="M3 11.1464C3 9.42216 4.39775 8.02441 6.12195 8.02441H31.0976C32.8218 8.02441 34.2195 9.42216 34.2195 11.1464V29.8781C34.2195 31.6023 32.8218 33 31.0976 33H6.12195C4.39775 33 3 31.6023 3 29.8781V11.1464Z" fill="white" fill-opacity="0.4"/>
        </g>
        <g filter="url(#filter2_dd_0_371)">
        <path d="M3 21.0333C3 20.4237 3.27802 19.8474 3.75513 19.468L9.3435 15.0237C10.6825 13.9588 12.6033 14.0263 13.8633 15.1826L21.4798 22.1716C22.3874 23.0045 23.6721 23.2947 24.8527 22.9336L31.6345 20.8592C32.92 20.466 34.2195 21.4275 34.2195 22.7717V29.5514C34.2195 31.4559 32.6665 32.9998 30.7507 32.9998H6.46883C4.55305 32.9998 3 31.4559 3 29.5514V21.0333Z" fill="white"/>
        </g>
        <g filter="url(#filter3_dd_0_371)">
        <path d="M27.9758 14.2684C27.9758 15.9926 26.5781 17.3904 24.8539 17.3904C23.1297 17.3904 21.7319 15.9926 21.7319 14.2684C21.7319 12.5442 23.1297 11.1465 24.8539 11.1465C26.5781 11.1465 27.9758 12.5442 27.9758 14.2684Z" fill="white"/>
        </g>
      </g>
      <defs>
      <filter id="filter0_dd_0_371" x="0.318359" y="0.537598" width="42.3364" height="37.3984" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
      <feFlood flood-opacity="0" result="BackgroundImageFix"/>
      <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
      <feOffset dy="3"/>
      <feGaussianBlur stdDeviation="2"/>
      <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.2 0"/>
      <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_0_371"/>
      <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
      <feOffset dy="1"/>
      <feGaussianBlur stdDeviation="1"/>
      <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0"/>
      <feBlend mode="normal" in2="effect1_dropShadow_0_371" result="effect2_dropShadow_0_371"/>
      <feBlend mode="normal" in="SourceGraphic" in2="effect2_dropShadow_0_371" result="shape"/>
      </filter>
      <filter id="filter1_d_0_371" x="1" y="7.02441" width="35.2197" height="28.9756" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
      <feFlood flood-opacity="0" result="BackgroundImageFix"/>
      <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
      <feOffset dy="1"/>
      <feGaussianBlur stdDeviation="1"/>
      <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0"/>
      <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_0_371"/>
      <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_0_371" result="shape"/>
      </filter>
      <filter id="filter2_dd_0_371" x="1" y="13.2681" width="35.2197" height="22.7319" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
      <feFlood flood-opacity="0" result="BackgroundImageFix"/>
      <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
      <feOffset dy="1"/>
      <feGaussianBlur stdDeviation="1"/>
      <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.2 0"/>
      <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_0_371"/>
      <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
      <feOffset dy="1"/>
      <feGaussianBlur stdDeviation="0.5"/>
      <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0"/>
      <feBlend mode="normal" in2="effect1_dropShadow_0_371" result="effect2_dropShadow_0_371"/>
      <feBlend mode="normal" in="SourceGraphic" in2="effect2_dropShadow_0_371" result="shape"/>
      </filter>
      <filter id="filter3_dd_0_371" x="19.7319" y="10.1465" width="10.2441" height="10.2441" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
      <feFlood flood-opacity="0" result="BackgroundImageFix"/>
      <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
      <feOffset dy="1"/>
      <feGaussianBlur stdDeviation="1"/>
      <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.2 0"/>
      <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_0_371"/>
      <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
      <feOffset dy="1"/>
      <feGaussianBlur stdDeviation="0.5"/>
      <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0"/>
      <feBlend mode="normal" in2="effect1_dropShadow_0_371" result="effect2_dropShadow_0_371"/>
      <feBlend mode="normal" in="SourceGraphic" in2="effect2_dropShadow_0_371" result="shape"/>
      </filter>
      </defs>
    </svg>
  </div>
</template>

<script>
export default {
  props: {},
  data() {
    return {};
  },
  computed: {},
  methods: {
    toggle() { 
      return this.$emit("toggle");
    }
  },
  components: {}
};
</script>

<style lang="scss" scoped>
.btn-wallpaper {
  position: fixed;
  top: 30px;
  right: 30px;
  .btn-wallpaper-icon-back, .btn-wallpaper-icon-front {
    transform-origin: top right;
    transition: transform 0.4s ease;
  }
  &:hover {
    .btn-wallpaper-icon-back {
      transform: rotate3d(0, 0, 1, 3deg);
    }
    .btn-wallpaper-icon-front {
      transform: rotate3d(0, 0, 1, -6deg);
    }
  }
}
</style>
